<template>
  <v-app>
    <v-container fluid class="pa-0 ma-0 mb-16">
      <v-row class="mx-0 px-0 mt-0 pt-0 mb-16 pb-16" style="flex-wrap: nowrap">
        <v-col cols="auto" class="mt-0 pt-0 px-0 mx-0">
          <nav-drawer
            ref="navDrawer"
            :app="currentApp"
            :page="currentPage"
            :show="showNavBar"
            @closeNavBar="showNavBar = false"></nav-drawer>
        </v-col>
        <v-col class="mt-0 pt-0 px-0 mx-0">
          <app-header
            ref="appHeader"
            :notificationCount="notificationCount"
            :selectedApplication.sync="currentApp"
            :pageDisplayInHeader="currentPage"
            @settingsClicked="settingsSidebar = true"
            @navClicked="showNavBar = !showNavBar"></app-header>
          <!-- Display current router view. -->
          <v-main>
            <transition name="slide-x-transition" mode="out-in">
              <router-view :key="$route.fullPath"> </router-view>
            </transition>
          </v-main>
        </v-col>
      </v-row>
    </v-container>
    <!-- Side menu -->
    <!-- <v-navigation-drawer
      v-if="user.permissions"
      v-model="settingsSidebar"
      temporary
      fixed
      light
      right
      width="280">
      <v-list>
        <v-subheader> Links </v-subheader>
        <v-list-item v-if="user.permissions.is_dev" @click="goToAdminSite()">
          <v-list-item-action>
            <v-icon>mdi-bank</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> Admin </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-subheader>
          <v-col> Settings </v-col>
          <v-progress-circular
            v-if="userSettingsUpdating"
            size="20"
            :width="2"
            indeterminate
            color="primary"></v-progress-circular>
        </v-subheader>
        <v-list-item @click="userSettings()">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> User </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-divider></v-divider>
    </v-navigation-drawer> -->
    <v-footer absolute dark padless class="primary darken-1">
      <v-row>
        <v-col>
          <v-row>
            <v-col class="d-flex justify-center py-6">
              <span>
                Contact
                <v-icon small class="ml-1 mr-1">mdi-email-outline</v-icon>
                <a class="white--text" href="mailto:muebeletech@gmail.com">muebeletech@gmail.com </a>
              </span>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-row>
            <v-col class="d-flex justify-center py-6">
              <v-icon small class="mr-1">mdi-copyright</v-icon> {{ new Date().getFullYear() }} — Michael
              Uebele
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>

    <alert> </alert>
  </v-app>
</template>
<style>
#app {
  background-color: #f9f9fe;
}
/* Makes Vuetify disabled elements have black text instead of grey */
[class$="--disabled"],
[class*="--disabled "] * {
  color: #888 !important;
}
:disabled {
  color: #444 !important;
}

.notification-card-text {
  max-width: 260px;
}

/* Makes data tables alternate colors. */
.v-data-table tr:nth-of-type(even) {
  background-color: #eeeeee;
}

/* Freezes data table's first column and ID header. */
table > tbody > tr > td:nth-child(1),
table > thead > tr > th:nth-child(1).id {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
  background-color: white;
}

/* Makes data table's first column have a noticeable border. */
table > tbody > tr > td:nth-child(1) {
  border-right: 1.5px solid #e0e0e0;
}

/* Makes data table's first column alternate colors. */
.v-data-table tr:nth-of-type(even) > td:nth-child(1) {
  background: #eeeeee;
}

/* Prevents data table hovering. */
.v-data-table tr:nth-of-type(odd):hover {
  background: white !important;
}

/* Custom classes to decrease font-size on smaller screens */
.smallText {
  font-size: 90%;
}
.smallerText {
  font-size: 80%;
}
.smallestText {
  font-size: 70%;
}
</style>
<script>
import * as auth from "@/javascript/auth"
import * as functions from "@/javascript/functions"
import { OrderStatus, Permissions } from "@/javascript/constants"
import * as router from "@/javascript/router"
import { ENV_SETTINGS } from "@/javascript/env_settings"
import nav_drawer from "@/components/nav_drawer.vue"
import app_header from "@/components/app_header.vue"
import alert from "@/components/alert.vue"

export default {
  name: "App",
  components: {
    "nav-drawer": nav_drawer,
    "app-header": app_header,
    "alert": alert,
  },
  data: function () {
    return {
      currentEnv: ENV_SETTINGS,
      dataLoaded: false,
      dataRefreshing: false,
      clearingNotifications: false,
      currentApp: null,
      currentPage: "Project Starfish",
      feedback: {},
      feedbackDialog: false,
      feedbackTypes: ["Bug Report", "UI Suggestion", "Feature Request"],
      showNavBar: false,
      settingsSidebar: false,
      notificationSidebar: false,
      notifications: [],
      notificationCount: 0,
      userSettingsUpdating: false,
      appStyle: " width: window.screen.availWidth; height: window.screen.availHeight",
    }
  },
  computed: {
    auth_token: function () {
      return this.$store.state.auth_token
    },
    user: function () {
      return this.$store.state.user
    },
    lastRefreshed: function () {
      return this.$store.state.lastRefreshed
    },
    dataLoading: {
      get() {
        return this.$store.state.dataLoading
      },
      set(val) {
        this.$store.state.dataLoading = val
      },
    },
    srsNewWordList() {
      return this.$store.state.srsNewWordList
    },
    srsRecallList() {
      return this.$store.state.srsRecallList
    },
    srsProductionList() {
      return this.$store.state.srsProductionList
    },
  },
  metaInfo: function () {
    return {
      title: "Project Starfish",
      meta: [],
    }
  },
  methods: {
    feedbackSubmit() {
      let formData = new FormData()

      formData.append("files", this.feedback.attachment)
      delete this.feedback.attachment

      this.feedback.user_id = this.user.id
      formData.append("feedback", JSON.stringify(this.feedback))

      this.$django_api.post("core/feedback/", formData).then(() => {
        let alert = {
          type: "success",
          message: "Your feedback has been submitted.",
        }

        this.$store.dispatch("set_alert", alert)
      })

      this.feedback = {}
      this.feedbackDialog = false
    },

    goToAdminSite() {
      window.open(this.$django_api.defaults.baseURL + "admin/", "_blank")
    },
    userSettings() {
      this.$router.push("/user_settings")
    },
    async loadData() {
      if (!this.dataLoaded && !this.dataLoading) {
        this.dataLoading = true
        this.$refs.navDrawer.loadNavPages()
        this.$refs.appHeader.loadNavApps()

        await this.$django_api.get("core/user").then((response) => {
          this.$store.dispatch("set_user", response.data)
        })

        // SRS Data
        let params = `language=${this.user.srs_user.language}`
        if (this.user.srs_user.level) params = `${params}&level=${this.user.srs_user.level}`
        if (this.user.srs_user.partOfSpeech)
          params = `${params}&part_of_speech=${this.user.srs_user.partOfSpeech}`
        if (this.user.srs_user.category) params = `${params}&category=${this.user.srs_user.category}`

        await this.$django_api.get(`srs/lexeme?${params}&new_words=True&pagination=1`).then((response) => {
          this.$store.state.srsNewWordList = response.data.lexemes
          this.$store.state.srsNewWordTotal = response.data.total
        })
        await this.$django_api.get(`srs/userword?${params}&stage=Recall`).then((response) => {
          this.$store.state.srsRecallList = response.data
        })
        await this.$django_api.get(`srs/userword?${params}&stage=Production`).then((response) => {
          this.$store.state.srsProductionList = response.data
        })

        // this.$django_api.get("core/user?all=true").then((response) => {
        //   this.$store.state.allUsers = response.data
        // })
        this.$django_api.get("srs/language").then((response) => {
          this.$store.state.allLanguages = response.data
        })
        this.$django_api.get(`srs/categories?language=${this.user.srs_user.language}`).then((response) => {
          this.$store.state.allCategories = response.data
        })

        // Load the rest of words
        // this.$django_api
        //   .get(`srs/lexeme?${params}&new_words=True&pagination=2`)
        //   .then((response) => {
        //     this.$store.state.srsNewWordList = this.$store.state.srsNewWordList.concat(
        //       response.data.lexemes
        //     )
        //   })

        this.dataLoaded = true
        this.dataLoading = false
        this.$store.state.lastRefreshed = Date.now()
      }
    },
    refreshData() {
      if (this.auth_token) {
        this.dataRefreshing = true

        this.$store.state.lastRefreshed = Date.now()
        setTimeout(() => {
          this.dataRefreshing = false
        }, 1500)
      }
    },
    async updateComponents() {
      let userLoaded = Object.keys(this.user).length > 0

      // Sanity check. Make sure the user data is loaded before doing anything else.
      if (this.auth_token && !userLoaded) {
        await this.$django_api.get(`core/user/`).then((response) => {
          this.$store.state.user = response.data
        })
      }

      if (this.auth_token && userLoaded) {
        this.loadData()

        let slugs = this.$route.path.split("/")
        if (slugs.includes("app")) {
          this.currentApp = functions.slugToName(slugs[slugs.indexOf("app") + 1]) // app = first slug after 'app/' in URL
          this.currentPage = functions.slugToName(slugs.pop()) // page = last slug of URL
        } else {
          this.currentApp = "Home"
          this.currentPage = "Project Starfish"
        }
      } else {
        this.currentApp = "Home"
        this.currentPage = "Project Starfish"
      }
    },
    checkHighlightedText(event) {
      let text = ""
      if (window.getSelection) {
        text = window.getSelection().toString()
      } else if (document.selection && document.selection.type != "Control") {
        text = document.selection.createRange().text
      }

      this.$store.state.highlightedText = text
    },
  },
  watch: {
    // Watch for auth_token change to fetch user profile.
    auth_token: async function (val) {
      // If it's just been deleted, just let it go.
      if (val) {
        // We need to wait for the user to be fetched to decide what we are doing.
        let success = await auth.fetch_user_permissions()
        if (success) {
          this.dataLoaded = false // re-load data on login
          location.reload() // refresh page
        }
        // If the user didn't get found/authorized, we 401 them.
        else {
          this.$router.push("/401")
        }
      } else {
        this.$refs.navDrawer.clearNavPages()
      }
    },
    $route(to, from) {
      this.updateComponents()
    },
    user: {
      handler(val) {
        this.$refs.navDrawer.loadNavPages()
      },
    },
    srsNewWordList: {
      handler(val) {
        if (this.auth_token) {
          router.getPage("learn").notifications = val.length

          this.$refs.navDrawer.forceUpdate()
        }
      },
      deep: true,
    },
    srsRecallList: {
      handler(val) {
        if (this.auth_token) {
          router.getPage("recall").notifications = val.length

          this.$refs.navDrawer.forceUpdate()
        }
      },
      deep: true,
    },
    srsProductionList: {
      handler(val) {
        if (this.auth_token) {
          router.getPage("production").notifications = val.length

          this.$refs.navDrawer.forceUpdate()
        }
      },
      deep: true,
    },
  },
  mounted: function () {
    if ("token" in this.$route.query) {
      this.$store.dispatch("set_auth_token", this.$route.query.token)
    }
    this.updateComponents()
    setInterval(this.refreshData, 60000) // refresh data every 60 seconds

    window.addEventListener("mouseup", this.checkHighlightedText)
  },
}
</script>
